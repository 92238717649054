




























































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import salesCategoryApi from '@/api/salesCategoryKPIApi';
import employeeApi from '@/api/employeeApi';
import { Establishment, DateRange, SalesCategoryKPI, Employee, CATEGORY_TABLE } from '@/common';
import { format } from 'date-fns';
import Loading from '@/components/Loading.vue';
import Sidebar from '@/components/Sidebar';
import EstablishmentSelect from '@/components/EstablishmentSelect.vue';
import PeriodPicker from '@/components/PeriodPicker.vue';
import EmployeeSelect from '@/components/EmployeeSelect.vue';

const appModule = namespace('app');

interface Data {
  name: string;
  employeeTotal: number;
  employeeTotalPercentage: number;
  total: number;
  totalPercentage: number;
}

@Component({
  components: {
    Loading,
    Sidebar,
    EstablishmentSelect,
    PeriodPicker,
    EmployeeSelect,
  },
})
export default class EmployeeView extends Vue {
  public employees: Employee[] = [];
  public totalSales: SalesCategoryKPI[] = [];
  public employeeSales: SalesCategoryKPI[] = [];

  public employeeSelector: number | null = null;
  public loading = false;

  @appModule.State('establishment')
  public establishment!: Establishment;

  @appModule.Getter('selectedRange')
  public selectedRange!: DateRange | null;

  public async mounted() {
    this.loadEmployees();
  }

  get employee() {
    const result = this.employees.find(e => e.id === this.employeeSelector);
    if (!result) {
      return null;
    } else {
      return result;
    }
  }

  get employeeName() {
    if (this.employee) {
      return this.employee.firstname + ' ' + this.employee.lastname;
    } else {
      return null;
    }
  }

  get employeeOptions() {
    const result: Array<{ value: number | null; text: string }> = [];
    result.push({ value: null, text: 'Selecteer een medewerker' });

    this.employees.forEach(e =>
      result.push({
        value: e.id,
        text: `${e.firstname} ${e.lastname} (${e.nickname})`,
      }),
    );

    return result;
  }

  get noData() {
    return this.totalSales.length === 0 || this.employeeSales.length === 0;
  }

  get categories() {
    return CATEGORY_TABLE[this.establishment];
  }

  get lunchDataFoodNew() {
    return this.getLunchDataByCategory(this.categories.CAT_LUNCH);
  }

  get lunchDataCoffeeNew() {
    return this.getLunchDataByCategory(this.categories.CAT_COFFEE);
  }

  get lunchDataDrinksNew() {
    return this.getLunchDataByCategory(this.categories.CAT_DRINKS);
  }

  get dinnerDataFoodNew() {
    return this.getDinnerDataByCategory(this.categories.CAT_DINNER);
  }

  get dinnerDataCoffeeNew() {
    return this.getDinnerDataByCategory(this.categories.CAT_COFFEE);
  }

  get dinnerDataDrinksNew() {
    return this.getDinnerDataByCategory(this.categories.CAT_DRINKS);
  }

  @Watch('establishment')
  public onEstablishmentChange() {
    this.loadEmployees();
  }

  @Watch('employee')
  public getEmployeeName() {
    this.loadData();
  }

  @Watch('selectedRange')
  public onPeriodChange() {
    this.loadData();
  }

  public async loadEmployees() {
    this.employees = await employeeApi.findAllNonHidden(this.establishment);
  }

  public async loadData() {
    if (this.selectedRange === null || this.employee === null) {
      return;
    }

    this.loading = true;

    const d1 = format(this.selectedRange.d1, 'yyyy-MM-dd');
    const d2 = format(this.selectedRange.d2, 'yyyy-MM-dd');

    try {
      this.totalSales = await salesCategoryApi.get(this.establishment, d1, d2);
      this.employeeSales = await salesCategoryApi.getByEmployee(
        this.establishment,
        this.employee.id,
        d1,
        d2,
      );
    } finally {
      this.loading = false;
    }
  }

  public getLunchDataByCategory(cat: string[]) {
    if (this.noData) {
      return [];
    }

    const tSales = cat
      .filter(cat => this.totalSales.findIndex(s => s.categoryName === cat) !== -1)
      .map(cat => {
        const idx = this.totalSales.findIndex(s => s.categoryName === cat);
        return this.totalSales[idx];
      });
    const eSales = cat
      .filter(cat => this.employeeSales.findIndex(s => s.categoryName === cat) !== -1)
      .map(cat => {
        const idx = this.employeeSales.findIndex(s => s.categoryName === cat);
        return this.employeeSales[idx];
      });

    const result: Data[] = [];
    const total = tSales?.[0]?.lunch;
    const totalEmployee = eSales?.[0]?.lunch;

    if (total === undefined || totalEmployee === undefined) {
      return result;
    }

    for (let i = 0; i < tSales.length; i++) {
      const t = tSales[i];
      const e = eSales[i];

      result.push({
        name: t.categoryName,
        employeeTotal: e.lunch,
        employeeTotalPercentage: totalEmployee === 0 ? 0 : e.lunch / totalEmployee,
        total: t.lunch,
        totalPercentage: total === 0 ? 0 : t.lunch / total,
      });
    }

    return result;
  }

  public getDinnerDataByCategory(cat: string[]) {
    if (this.noData) {
      return [];
    }

    const tSales = cat
      .filter(cat => this.totalSales.findIndex(s => s.categoryName === cat) !== -1)
      .map(cat => {
        const idx = this.totalSales.findIndex(s => s.categoryName === cat);
        return this.totalSales[idx];
      });
    const eSales = cat
      .filter(cat => this.employeeSales.findIndex(s => s.categoryName === cat) !== -1)
      .map(cat => {
        const idx = this.employeeSales.findIndex(s => s.categoryName === cat);
        return this.employeeSales[idx];
      });

    const result: Data[] = [];
    const total = tSales?.[0]?.dinner;
    const totalEmployee = eSales?.[0]?.dinner;

    if (total === undefined || totalEmployee === undefined) {
      return result;
    }

    for (let i = 0; i < tSales.length; i++) {
      const t = tSales[i];
      const e = eSales[i];

      result.push({
        name: t.categoryName,
        employeeTotal: e.dinner,
        employeeTotalPercentage: totalEmployee === 0 ? 0 : e.dinner / totalEmployee,
        total: t.dinner,
        totalPercentage: total === 0 ? 0 : t.dinner / total,
      });
    }

    return result;
  }
}
