






































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import draggable from 'vuedraggable';
import Api from '@/api/productApi';
import { Establishment, Product } from '@/common';
import { ProductList, ProductListDto } from '@/components/ProductList';
import EstablishmentSelect from '@/components/EstablishmentSelect.vue';

interface FilterableItem {
  show: boolean;
  product: Product;
}

const appModule = namespace('app');
const currencyFmt = new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
});

@Component({
  components: { draggable, EstablishmentSelect },
})
export default class ProductListForm extends Vue {
  @Prop({ default: () => null })
  public populateWith!: ProductList | null;

  @appModule.State('establishment')
  public establishment!: Establishment;

  @appModule.Action
  public changeEstablishment!: (establishment: Establishment) => void;

  public showModifiers = false;

  public model: ProductListDto = {
    name: '',
    items: [],
  };

  public products: Product[] = [];
  public productList: Product[] = [];

  public productSearch = '';
  public productListSearch = '';

  @Watch('establishment', { immediate: true })
  public async loadProducts() {
    this.products = await Api.findAll(this.establishment, true);
    const lookup = new Map(this.products.map(i => [i.id, i]));

    if (this.populateWith) {
      if (this.establishment !== this.populateWith.establishment) {
        this.changeEstablishment(this.populateWith.establishment as Establishment);
      }

      this.model.name = this.populateWith.name;
      this.model.items = this.populateWith.items.map(x => x.productId);
      this.products = this.products.filter(x => !this.model.items.includes(x.id));
      this.productList = this.populateWith.items
        .map(x => lookup.get(x.productId))
        .filter(x => x) as Product[];
    } else {
      this.model.name = '';
      this.model.items = [];
    }
  }

  get filteredProducts() {
    return this.products.map(x => {
      return {
        hide:
          (!this.showModifiers && x.price === 0) ||
          !x.name.toLowerCase().includes(this.productSearch.toLowerCase()),
        product: x,
      };
    });
  }

  get filteredProductList() {
    return this.productList.map(x => {
      return {
        hide: !x.name.toLowerCase().includes(this.productListSearch.toLowerCase()),
        product: x,
      };
    });
  }

  @Watch('productList')
  public updateModel() {
    this.model.items = this.productList.map(x => x.id);
  }

  public formatPrice(price?: number) {
    if (price !== undefined) {
      return currencyFmt.format(price);
    }
    return '';
  }
}
