





































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { format, parseISO, setMonth } from 'date-fns';
import { nl as locale } from 'date-fns/locale';
import api, { Promotion } from '@/api/promotion';
import { Establishment, Period, PeriodType, DateRange } from '@/common';
import Sidebar from '@/components/Sidebar';
import Loading from '@/components/Loading.vue';
import EstablishmentSelect from '@/components/EstablishmentSelect.vue';
import PeriodPicker from '@/components/PeriodPicker.vue';

const appModule = namespace('app');

@Component({
  components: {
    Loading,
    Sidebar,
    EstablishmentSelect,
    PeriodPicker,
  },
})
export default class PromotionView extends Vue {
  @appModule.State('establishment')
  public establishment!: Establishment;

  @appModule.Getter('selectedRange')
  public selectedRange!: DateRange | null;

  @appModule.State('period')
  public period!: Period;

  public loading = false;
  public data: Promotion[] = [];

  public tableFields = [
    {
      key: 'name',
      sortable: true,
      label: 'Promo',
    },
    {
      key: 'amount',
      sortable: true,
      label: 'Aantal',
      tdClass: 'text-right',
      thClass: 'text-right',
    },
    {
      key: 'total',
      sortable: true,
      label: 'Korting',
      tdClass: 'text-right',
      thClass: 'text-right',
    },
  ];

  public async mounted() {
    await this.loadData();
  }

  get title() {
    switch (this.period.type) {
      case PeriodType.YEAR:
        return `Promoties: ${this.period.year}`;
      case PeriodType.MONTH:
        return `Promoties: ${format(setMonth(new Date(), this.period.month), 'MMMM', { locale })} ${
          this.period.year
        }`;
      case PeriodType.WEEK:
        return `Promoties: week ${this.period.week} - ${this.period.year}`;
      case PeriodType.DAY:
        return `Promoties: ${format(parseISO(this.period.date), 'dd-MM-yyyy')}`;
      default:
        return `Promoties`;
    }
  }

  @Watch('selectedRange')
  public async onDateRangeChange() {
    this.loadData();
  }

  public async loadData() {
    if (!this.selectedRange) {
      return;
    }

    this.loading = true;

    const d1 = format(this.selectedRange.d1, 'yyyy-MM-dd');
    const d2 = format(this.selectedRange.d2, 'yyyy-MM-dd');
    try {
      this.data = await api.list(this.establishment, d1, d2);
    } finally {
      this.loading = false;
    }
  }
}
