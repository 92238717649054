
































































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Loading from '@/components/Loading.vue';
import EstablishmentSelect from '@/components/EstablishmentSelect.vue';
import { DataList, DataModel } from '@/components/ProductList';
import { Establishment } from '@/common';

const appModule = namespace('app');

@Component({
  components: { Loading, EstablishmentSelect, DataList, DataModel },
})
export default class ProductListSettingsView extends Vue {
  @appModule.State('establishment')
  public establishment!: Establishment;
}
