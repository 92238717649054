import client from './alohaApiClient';
import {
  SalesData,
  Establishment,
  GeneralKPIData,
  ProductCategoryKPIData,
  EmployeeProductCategoryKPIDataDto,
} from '@/common';

const kpiApi = {
  async getGlobalKPI(establishment: Establishment, d1: string, d2: string) {
    const params = { d1, d2 };
    const response = await client.get<GeneralKPIData>(`${establishment}/kpi/general`, { params });
    return response.data;
  },

  async getProductCategoryKPI(establishment: Establishment, d1: string, d2: string) {
    const params = { d1, d2 };
    const response = await client.get<ProductCategoryKPIData>(
      `${establishment}/kpi/product-category`,
      { params },
    );
    return response.data;
  },

  async getEmployeeProductCategoryKPIData(establishment: Establishment, date: string) {
    const params = { date };
    const response = await client.get<EmployeeProductCategoryKPIDataDto>(
      `${establishment}/kpi/employee-product-category`,
      { params },
    );
    return response.data;
  },

  async getSalesData(establishment: Establishment, d1: string, d2: string) {
    const params = { d1, d2 };
    const response = await client.get<SalesData>(`${establishment}/sales`, { params });
    return response.data;
  },
};

export default kpiApi;
export { kpiApi };
