


















import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { format } from 'date-fns';
import { Establishment, DateRange } from '@/common';
import api, { Void } from '@/api/voids';

const appModule = namespace('app');

@Component
export default class Voids extends Vue {
  @appModule.State('establishment')
  public establishment!: Establishment;

  @appModule.Getter('selectedRange')
  public selectedRange!: DateRange | null;

  public loading = false;
  public data: Void[] = [];

  public tableFields = [
    {
      key: 'time',
      sortable: true,
      label: 'Tijd',
    },
    {
      key: 'table',
      sortable: true,
      label: 'Tafel',
    },
    {
      key: 'employee',
      sortable: true,
      label: 'Medewerker',
    },
    {
      key: 'manager',
      sortable: true,
      label: 'Manager',
    },
    {
      key: 'item',
      sortable: true,
      label: 'Product',
    },
    {
      key: 'price.formatted',
      label: 'Bedrag',
      tdClass: 'text-right',
      thClass: 'text-right',
    },
    {
      key: 'reason',
      sortable: true,
      label: 'Reden',
      tdClass: 'text-right',
      thClass: 'text-right',
    },
  ];

  public async mounted() {
    await this.loadData();
  }

  @Watch('selectedRange')
  public async onDateRangeChange() {
    this.loadData();
  }

  public async loadData() {
    if (!this.selectedRange) {
      return;
    }

    this.loading = true;

    const d1 = format(this.selectedRange.d1, 'yyyy-MM-dd');
    const d2 = format(this.selectedRange.d2, 'yyyy-MM-dd');
    try {
      this.data = await api.list(this.establishment, d1, d2);
    } finally {
      this.loading = false;
    }
  }

  public formatReason(val: string) {
    return val.replace('\\n', ' ');
  }

  get totalAmount() {
    let amount = 0;
    this.data.forEach(e => {
      if (e.price.amount) {
        amount = amount + e.price.amount;
      }
    });
    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(amount);
  }
}
