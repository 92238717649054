import platformApiClient from './platformApiClient';
import alohaApiClient from './alohaApiClient';
import { Product, OrderedProduct, Establishment } from '@/common';

const alohaResource = 'product';
const orderedResource = 'ordered-product';

const productApi = {
  async findAll(establishment: Establishment, showModifiers: boolean) {
    const response = await alohaApiClient.get<Product[]>(`${establishment}/${alohaResource}`, {
      params: { showModifiers },
    });
    return response.data;
  },

  async findAllOrdered() {
    const response = await platformApiClient.get<OrderedProduct[]>(orderedResource);
    return response.data;
  },

  async save(products: Product[]) {
    const response = await platformApiClient.post<Product[]>(orderedResource, products);
    return response.data;
  },
};

export default productApi;
export { productApi };
