import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { EmployeeState } from '.';

export default {
  allEmployees: state => Object.values(state.employees),
  employeeById: state => (id: number | number[]) => {
    if (!Array.isArray(id)) {
      id = [id];
    }

    return id.map(i => state.employees[i]);
  },
} as GetterTree<EmployeeState, RootState>;
