












import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Employee, Establishment } from '@/common/types';
import employeeApi from '@/api/employeeApi';

const appModule = namespace('app');

@Component
export default class EmployeeSelect extends Vue {
  public employees: Employee[] = [];
  public employeeSelector: number | null = null;

  @appModule.State('establishment')
  public establishment!: Establishment;

  get employeeOptions() {
    const result: Array<{ value: number | null; text: string }> = [];
    result.push({ value: null, text: 'Selecteer een medewerker' });

    this.employees.forEach(e =>
      result.push({
        value: e.id,
        text: `${e.firstname} ${e.lastname} (${e.nickname})`,
      }),
    );

    return result;
  }

  public async loadEmployees() {
    this.employees = await employeeApi.findAllNonHidden(this.establishment);
  }

  public async mounted() {
    this.loadEmployees();
  }
}
