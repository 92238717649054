import client from './platformApiClient';
import { Dictionary, Establishment, GeneralKPI } from '@/common';

const resource = 'kpi/general';

const generalKPIApi = {
  async getPerDay(establishment: Establishment, d1: string, d2: string) {
    const params = { d1, d2 };
    const response = await client.get<Dictionary<GeneralKPI>>(
      `${resource}/${establishment}/per-day`,
      { params },
    );
    return response.data;
  },

  async getPerWeek(establishment: Establishment, d1: string, d2: string) {
    const params = { d1, d2 };
    const response = await client.get<Dictionary<GeneralKPI>>(
      `${resource}/${establishment}/per-week`,
      { params },
    );
    return response.data;
  },

  async getPerMonth(establishment: Establishment, d1: string, d2: string) {
    const params = { d1, d2 };
    const response = await client.get<Dictionary<GeneralKPI>>(
      `${resource}/${establishment}/per-month`,
      { params },
    );
    return response.data;
  },
};

export default generalKPIApi;
