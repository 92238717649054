import { Vue, Component, Prop } from 'vue-property-decorator';

import apiClient from '@/api/platformApiClient';
import { ProductList, ProductListDto } from '@/components/ProductList/types';
import { Establishment } from '@/common';

const END_POINT = 'product-list';

@Component
export default class DataModel extends Vue {
  @Prop({ default: () => null })
  public id!: number | null;
  @Prop({ default: () => null })
  public entity!: ProductList | null;

  public loading = false;
  public error: string | null = null;
  public data = this.entity;

  public async mounted() {
    if (!this.entity && this.id) {
      this.find();
    }
  }

  public async find() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    try {
      const response = await apiClient.get<ProductList>(`${END_POINT}/${this.id}`);
      this.error = null;
      this.data = response.data;
    } catch (e) {
      this.error = e.response;
    } finally {
      this.loading = false;
    }
  }

  public async create(establishment: Establishment, data: ProductListDto) {
    if (this.loading) {
      return;
    }

    this.loading = true;
    try {
      const response = await apiClient.post<ProductList>(`${establishment}/${END_POINT}`, data);
      this.error = null;
      this.$emit('created', response.data);
    } catch (e) {
      this.error = e.response;
    } finally {
      this.loading = false;
    }
  }

  public async update(data: ProductListDto) {
    if (this.loading) {
      return;
    }

    this.loading = true;
    try {
      const response = await apiClient.put<ProductList>(`/${END_POINT}/${this.id}`, data);
      this.error = null;
      this.data = response.data;
      this.$emit('updated');
    } catch (e) {
      this.error = e.response;
    } finally {
      this.loading = false;
    }
  }

  public async remove() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    try {
      await apiClient.delete<void>(`${END_POINT}/${this.id}`);
      this.error = null;
      this.$emit('deleted');
    } catch (e) {
      this.error = e.response;
    } finally {
      this.loading = false;
    }
  }

  public render() {
    if (!this.$scopedSlots.default) {
      return;
    }

    return this.$scopedSlots.default({
      loading: this.loading,
      error: this.error,
      data: this.data,
      create: this.create,
      update: this.update,
      remove: this.remove,
    });
  }
}
