






































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SettingsView extends Vue {
  get title() {
    return this.$route?.meta?.title ?? 'Instellingen';
  }
}
