import { MutationTree } from 'vuex';
import { AppState } from '.';
import { Establishment, Period } from '@/common/types';
import { SET_ACTIVE_ESTABLISHMENT, SET_PERIOD } from './mutation-types';

export default {
  [SET_ACTIVE_ESTABLISHMENT](state, establishment: Establishment) {
    state.establishment = establishment;
  },

  [SET_PERIOD](state, period: Period) {
    state.period = period;
  },
} as MutationTree<AppState>;
