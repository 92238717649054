import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { AppState } from '.';
import { Establishment, Period, Employee } from '@/common/types';
import { SET_ACTIVE_ESTABLISHMENT, SET_PERIOD, SET_EMPLOYEE } from './mutation-types';

export default {
  async changeEstablishment({ commit }, establishment: Establishment) {
    commit(SET_ACTIVE_ESTABLISHMENT, establishment);
  },

  async changePeriod({ commit }, period: Period) {
    commit(SET_PERIOD, period);
  },

  async changeEmployee({ commit }, employee: Employee) {
    commit(SET_EMPLOYEE, employee);
  },
} as ActionTree<AppState, RootState>;
