import client from './platformApiClient';
import { OrderedCategory } from '@/common';

const resource = 'ordered-category';

const categoryApi = {
  async findAll() {
    const response = await client.get<OrderedCategory[]>(`${resource}`);
    return response.data;
  },

  async save(categories: OrderedCategory[]) {
    const response = await client.post<OrderedCategory[]>(`${resource}`, categories);
    return response.data;
  },
};

export default categoryApi;
export { categoryApi };
