import client from '@/api/alohaApiClient';
import { Establishment, MonetaryAmount } from '@/common';

export interface CheckItem {
  employee: string;
  itemId: number;
  item: string;
  price: MonetaryAmount;
  discountPrice: MonetaryAmount;
  time: string;
}

export interface Check {
  dob: string;
  check: string;
  lines: CheckItem[];
}

const resource = 'check';

const api = {
  async list(establishment: Establishment, date: string, check: number) {
    const response = await client.get<Check>(`${establishment}/${resource}/${date}/${check}`);
    return response.data;
  },
};

export default api;
