import client from './platformApiClient';
import { EmployeeSettings, CreateEmployeeSettingsBatchDto, Establishment } from '@/common';

const resource = 'employee-settings';

const employeeSettingsApi = {
  async findAll(establishment: Establishment) {
    const response = await client.get<EmployeeSettings[]>(`${establishment}/${resource}`);
    return response.data;
  },

  async batchCreate(establishment: Establishment, dto: CreateEmployeeSettingsBatchDto) {
    await client.post<void>(`${establishment}/${resource}/batch`, dto);
  },
};

export default employeeSettingsApi;
export { employeeSettingsApi };
