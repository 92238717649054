





































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DataModel, ProductList } from '@/components/ProductList';
import ProductListForm from './ProductListForm.vue';
import { Establishment } from '@/common';

const appModule = namespace('app');

@Component({
  components: { DataModel, ProductListForm },
})
export default class CreateProductList extends Vue {
  @appModule.State('establishment')
  public establishment!: Establishment;

  public model = {
    name: '',
    items: [],
  };

  public onCreated(list: ProductList) {
    const id = String(list.id);
    this.$router.push({ name: 'settingsView.updateProductList', params: { id } });
  }
}
