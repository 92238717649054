



















































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import draggable from 'vuedraggable';
import api from '@/api/orderedCategoryApi';
import { Establishment, OrderedCategory } from '@/common';

const appModule = namespace('app');

@Component({
  components: { draggable },
})
export default class CategorySettingsView extends Vue {
  @appModule.State('establishment')
  public establishment!: Establishment;

  public categories: OrderedCategory[] = [];
  public dirty = false;
  public loading = false;

  public message = {
    dismissCountDown: 0,
    variant: '',
    text: '',
  };

  public async mounted() {
    this.categories = await api.findAll();
  }

  public async save() {
    if (this.loading) {
      return;
    }

    try {
      this.loading = true;
      this.categories = await api.save(this.categories);
      this.dirty = false;
      this.message.dismissCountDown = 5;
      this.message.variant = 'success';
      this.message.text = 'De wijzigingen zijn opgeslagen.';
    } catch (e) {
      this.message.dismissCountDown = 5;
      this.message.variant = 'danger';
      this.message.text =
        'Er is iets misgegaan bij het opslaan van de wijzigingen. Probeer het opnieuw.';
    } finally {
      this.loading = false;
    }
  }

  public dismissCountDownChanged(dismissCountDown: number) {
    this.message.dismissCountDown = dismissCountDown;
  }
}
