import client from '@/api/alohaApiClient';
import { Establishment, MonetaryAmount } from '@/common';

export interface Debtor {
  id: number;
  employee: string;
  manager: string;
  amount: MonetaryAmount;
  identification: string;
  check: string;
  date: string;
  time: string;
  firstname: string;
  middlename: string;
  lastname: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  state: string;
  zipcode: string;
  phone: string;
}

const debtorResource = 'debtor';
const debtorAccountResource = 'debtor-account';

const api = {
  async listDebtor(establishment: Establishment, d1: string, d2: string) {
    const params = { d1, d2 };
    const response = await client.get<Debtor[]>(`${establishment}/${debtorResource}`, { params });
    return response.data;
  },

  async listDebtorAccount(establishment: Establishment, d1: string, d2: string) {
    const params = { d1, d2 };
    const response = await client.get<Debtor[]>(`${establishment}/${debtorAccountResource}`, {
      params,
    });
    return response.data;
  },
};

export default api;
