import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { AppState } from '.';
import { PeriodType } from '@/common';
import {
  parseISO,
  startOfYear,
  setYear,
  addDays,
  addWeeks,
  addMonths,
  addYears,
  startOfISOWeek,
} from 'date-fns';

export default {
  selectedRange: state => {
    let d1;
    let d2;
    switch (state.period.type) {
      case PeriodType.DAY:
        d1 = parseISO(state.period.date);
        d2 = addDays(d1, 1);
        return { d1, d2 };
      case PeriodType.WEEK:
        d1 = startOfISOWeek(new Date(state.period.year, 0, state.period.week * 7));
        d2 = addWeeks(d1, 1);
        return { d1, d2 };
      case PeriodType.MONTH:
        d1 = new Date(state.period.year, state.period.month, 1);
        d2 = addMonths(d1, 1);
        return { d1, d2 };
      case PeriodType.YEAR:
        d1 = startOfYear(setYear(new Date(), state.period.year));
        d2 = addYears(d1, 1);
        return { d1, d2 };
      default:
        return null;
    }
  },
} as GetterTree<AppState, RootState>;
