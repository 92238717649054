










































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { GeneralKPI } from '@/common';
import DifferencePill from '@/components/DifferencePill.vue';

@Component({
  components: { DifferencePill },
})
export default class GeneralKPITable extends Vue {
  @Prop({ required: true })
  public dataset1!: GeneralKPI;
  @Prop({ required: true })
  public dataset2!: GeneralKPI;
}
