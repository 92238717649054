import axios from 'axios';

const baseURL = `${process.env.VUE_APP_PLATFORM_API_URL}/api/platform`;
const timeout = process.env.VUE_APP_API_TIMEOUT;
const headers = { Accept: 'application/json' };

const platformApi = axios.create({
  baseURL,
  timeout,
  headers,
});

export default platformApi;
export { platformApi };
