
































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import EstablishmentSelect from '@/components/EstablishmentSelect.vue';
import draggable from 'vuedraggable';
import employeeApi from '@/api/employeeApi';
import employeeSettingsApi from '@/api/employeeSettingsApi';
import { Establishment, Employee } from '@/common';

const appModule = namespace('app');

const compareEmployee = (e1: Employee, e2: Employee) => {
  const n1 = `${e1.firstname} ${e1.lastname}  ${e1.nickname}`;
  const n2 = `${e2.firstname} ${e2.lastname} ${e2.nickname}`;
  return n1.localeCompare(n2);
};

@Component({
  components: { draggable, EstablishmentSelect },
})
export default class EmployeeSettingsView extends Vue {
  @appModule.State('establishment')
  public establishment!: Establishment;

  public employees: Employee[] = [];
  public hiddenEmployees: Employee[] = [];
  public dirty = false;
  public loading = false;
  public loadingEmployees = false;

  public message = {
    dismissCountDown: 0,
    variant: '',
    text: '',
  };

  @Watch('establishment', { immediate: true })
  public async loadEmployees() {
    this.loadingEmployees = true;
    try {
      const settings = await employeeSettingsApi.findAll(this.establishment);
      const hidden = settings.filter(s => s.hidden).map(h => h.id);

      const employees = await employeeApi.findAll(this.establishment);

      this.hiddenEmployees = employees.filter(e => hidden.includes(e.id));
      this.employees = employees.filter(e => !hidden.includes(e.id));
    } catch (e) {
      this.message.dismissCountDown = 5;
      this.message.variant = 'danger';
      this.message.text =
        'Er is iets misgegaan bij het laden van de wijzigingen. Probeer het opnieuw.';
    } finally {
      this.loadingEmployees = false;
    }
  }

  public async save() {
    if (this.loading || !this.establishment) {
      return;
    }

    try {
      this.loading = true;

      const visible = this.employees.map(e => ({
        id: e.id,
        hidden: false,
      }));
      const hidden = this.hiddenEmployees.map(e => ({
        id: e.id,
        hidden: true,
      }));

      const data = visible.concat(hidden);
      await employeeSettingsApi.batchCreate(this.establishment, { data });

      this.dirty = false;
      this.message.dismissCountDown = 5;
      this.message.variant = 'success';
      this.message.text = 'De wijzigingen zijn opgeslagen.';
    } catch (e) {
      this.message.dismissCountDown = 5;
      this.message.variant = 'danger';
      this.message.text =
        'Er is iets misgegaan bij het opslaan van de wijzigingen. Probeer het opnieuw.';
    } finally {
      this.loading = false;
    }
  }

  public sortVisible() {
    this.employees.sort(compareEmployee);
  }

  public sortHidden() {
    this.hiddenEmployees.sort(compareEmployee);
  }

  public dismissCountDownChanged(dismissCountDown: number) {
    this.message.dismissCountDown = dismissCountDown;
  }
}
