import client from './alohaApiClient';
import { Employee, Establishment } from '@/common';

const resource = 'employees';

const employeeApi = {
  async getById(establishment: Establishment, id: number) {
    const response = await client.get<Employee>(`${establishment}/${resource}/${id}`);
    return response.data;
  },

  async findAll(establishment: Establishment) {
    const response = await client.get<Employee[]>(`${establishment}/${resource}`);
    return response.data;
  },

  async findAllNonHidden(establishment: Establishment) {
    const response = await client.get<Employee[]>(`${establishment}/${resource}/non-hidden`);
    return response.data;
  },
};

export default employeeApi;
export { employeeApi };
