



















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Dictionary, GeneralKPI } from '@/common';

enum ChartType {
  REVENUE,
  GUESTS,
  SPENDING,
}

@Component
export default class GeneralKPIChart extends Vue {
  @Prop({ required: true })
  public dataset1!: Dictionary<GeneralKPI> | null;
  @Prop({ required: true })
  public dataset1Label!: string;
  @Prop({ required: true })
  public dataset2!: Dictionary<GeneralKPI> | null;
  @Prop({ required: true })
  public dataset2Label!: string;
  @Prop({ required: true })
  public categories!: Array<{ label: string }>;

  public ChartType = ChartType;

  public chart = ChartType.REVENUE;

  get revenueDataSource() {
    if (!this.dataset1) {
      return null;
    }

    const lunchData = Object.values(this.dataset1).map(x => ({ value: x.revenue.lunch.amount }));
    const dinnerData = Object.values(this.dataset1).map(x => ({ value: x.revenue.dinner.amount }));

    let dataset = [];
    const dataset1 = [
      {
        seriesname: `Lunch (${this.dataset1Label})`,
        data: lunchData,
        color: '#5d62b5',
        alpha: '100',
      },
      {
        seriesname: `Dinner (${this.dataset1Label})`,
        data: dinnerData,
        color: '#29c3be',
        alpha: '100',
      },
    ];

    if (this.dataset2) {
      const prevLunchData = Object.values(this.dataset2).map(x => ({
        value: x.revenue.lunch.amount,
      }));
      const prevDinnerData = Object.values(this.dataset2).map(x => ({
        value: x.revenue.dinner.amount,
      }));
      const dataset2 = [
        {
          seriesname: `Lunch (${this.dataset2Label})`,
          data: prevLunchData,
          color: '#5d62b5',
          alpha: '50',
        },
        {
          seriesname: `Dinner (${this.dataset2Label})`,
          data: prevDinnerData,
          color: '#29c3be',
          alpha: '50',
        },
      ];
      dataset = [
        {
          dataset: dataset1,
        },
        {
          dataset: dataset2,
        },
      ];
    } else {
      dataset = dataset1;
    }

    return {
      chart: {
        caption: 'Omzet',
        subCaption: 'Lunch tot 17:00, Diner vanaf 17:00',
        xAxisname: 'Periode',
        yAxisName: 'Omzet',
        showSum: 1,
        numberPrefix: '€',
        theme: 'fusion',
      },
      categories: [
        {
          category: this.categories,
        },
      ],
      dataset,
    };
  }

  get guestsDataSource() {
    if (!this.dataset1) {
      return null;
    }

    const lunchData = Object.values(this.dataset1).map(x => ({ value: x.guests.lunch }));
    const dinnerData = Object.values(this.dataset1).map(x => ({ value: x.guests.dinner }));

    let dataset = [];
    const dataset1 = [
      {
        seriesname: `Lunch (${this.dataset1Label})`,
        data: lunchData,
        color: '#5d62b5',
        alpha: '100',
      },
      {
        seriesname: `Diner (${this.dataset1Label})`,
        data: dinnerData,
        color: '#29c3be',
        alpha: '100',
      },
    ];

    if (this.dataset2) {
      const prevLunchData = Object.values(this.dataset2).map(x => ({ value: x.guests.lunch }));
      const prevDinnerData = Object.values(this.dataset2).map(x => ({ value: x.guests.dinner }));
      const dataset2 = [
        {
          seriesname: `Lunch (${this.dataset2Label})`,
          data: prevLunchData,
          color: '#5d62b5',
          alpha: '50',
        },
        {
          seriesname: `Diner (${this.dataset2Label})`,
          data: prevDinnerData,
          color: '#29c3be',
          alpha: '50',
        },
      ];
      dataset = [
        {
          dataset: dataset1,
        },
        {
          dataset: dataset2,
        },
      ];
    } else {
      dataset = dataset1;
    }

    return {
      chart: {
        caption: 'Gasten',
        subCaption: 'Lunch tot 17:00, Diner vanaf 17:00',
        xAxisname: 'Periode',
        yAxisName: 'Gasten',
        showSum: 1,
        theme: 'fusion',
      },
      categories: [
        {
          category: this.categories,
        },
      ],
      dataset,
    };
  }

  get spendingDataSource() {
    if (!this.dataset1) {
      return null;
    }

    const averageData = Object.values(this.dataset1).map(x => ({ value: x.spending.total.amount }));
    const lunchData = Object.values(this.dataset1).map(x => ({ value: x.spending.lunch.amount }));
    const dinnerData = Object.values(this.dataset1).map(x => ({ value: x.spending.dinner.amount }));

    return {
      chart: {
        caption: 'Gemiddelde besteding',
        subCaption: 'Lunch tot 17:00, Diner vanaf 17:00',
        xAxisname: 'Periode',
        yAxisName: 'Besteding',
        numberPrefix: '€',
        theme: 'fusion',
      },
      categories: [
        {
          category: this.categories,
        },
      ],
      dataset: [
        {
          seriesname: 'Lunch',
          data: lunchData,
        },
        {
          seriesname: 'Diner',
          data: dinnerData,
        },
        {
          seriesname: 'Gemiddeld',
          data: averageData,
        },
      ],
    };
  }

  public isSelected(chart: ChartType) {
    return this.chart === chart ? 'secondary' : 'outline-secondary';
  }

  public select(chart: ChartType) {
    this.chart = chart;
  }
}
