








import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Sidebar extends Vue {
  public show = true;
}
