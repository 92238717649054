export enum Establishment {
  WALRUS_SN = 'WALRUS_SN',
  WALRUS_LWD = 'WALRUS_LWD',
  PROEFLOKAAL_SN = 'PROEFLOKAAL_SN',
  GROOTE_WIELEN = 'GROOTE_WIELEN',
}

export enum PeriodType {
  CUSTOM = 'CUSTOM',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export const CAT_LUNCH_WALRUS = [
  'Alle Lunch / Diner',
  'Lunch Specials',
  'Broodplankjes',
  'Soepen',
  'Special Soep',
  'Proeverij Soepen',
  'Alle Soepen',
  'Voorgerechten',
  'Special Voor',
  'Proeverij Voor',
  'Alle Voorgerechten',
  'Special Hoofd',
  'Nagerechten',
  'Special Na',
  'Proeverij Na',
  'Alle Nagerechten',
  'Water Bruis/Bron',
  'Menus',
  'Proeverij Menu',
];

export const CAT_DINNER_WALRUS = [
  'Alle Lunch / Diner',
  'Lunch Specials',
  'Broodplankjes',
  'Soepen',
  'Special Soep',
  'Proeverij Soepen',
  'Alle Soepen',
  'Voorgerechten',
  'Special Voor',
  'Proeverij Voor',
  'Alle Voorgerechten',
  'Special Hoofd',
  'Nagerechten',
  'Special Na',
  'Proeverij Na',
  'Alle Nagerechten',
  'Water Bruis/Bron',
  'Menus',
  'Proeverij Menu',
];

export const CAT_LUNCH_PROEFLOKAAL = [
  'Lunch Aantallen',
  'Lunch 4 items',
  'Extra items lunch',
  'Tafelwater',
];

export const CAT_DINNER_PROEFLOKAAL = ['Diner Aantallen', 'Alle Diner items', 'Tafelwater'];

export const CAT_LUNCH_GROOTE_WIELEN = [
  'Alle Lunch/Diner',
  'Lunch',
  'Lunch Specials',
  'Salades',
  'Hoofdgerechten',
  'Broodplankjes',
  'Voorgerechten',
  'Soepen',
  'Kreeft',
  'Chateaubriand',
  'Om te delen',
  'Bijgerechten',
  'Sides',
  'Desserts',
  'Water Bruis/Bron',
];

export const CAT_DINNER_GROOTE_WIELEN = [
  'Alle Lunch/Diner',
  'Lunch',
  'Lunch Specials',
  'Salades',
  'Hoofdgerechten',
  'Broodplankjes',
  'Voorgerechten',
  'Soepen',
  'Kreeft',
  'Chateaubriand',
  'Om te delen',
  'Bijgerechten',
  'Sides',
  'Desserts',
  'Water Bruis/Bron',
];

export const CAT_DRINKS_WALRUS = [
  'Alle dranken',
  'Bier Fles',
  'Bier Tap (ex.Pils)',
  'De Johannes',
  'Halve liters',
  'Cocktails',
  'Gin-Tonics',
  'Sapjes',
  'Grote Cola',
  'Wijnspecial Glas',
  'Wijnspecial Fles',
  'Fles Wijn',
  'Kaart wijnen',
  'Likeuren',
  'Whiskey + Cognac',
  'Borrel Plateaus',
];

export const CAT_DRINKS_PROEFLOKAAL = [
  'Alle dranken',
  'Bier vd tap',
  'Bier per fles',
  'Bier Groot',
  'Cocktails & GT',
  'Huiswijn Glas',
  'Huiswijn Fles',
  'Tafelwijn glas',
  'Tafelwijn Fles',
  'Aperatifs',
  'Likeuren',
  'Whiskey/Cognac',
  'Dessertwijn',
  'Grote Jus',
  'Proefplank',
];

export const CAT_DRINKS_GROOTE_WIELEN = [
  'Alle dranken',
  'Bier tap',
  'Bier fles',
  'Halve liters',
  'Cocktails',
  'Mocktails',
  'Gin-tonic',
  'Huiswijnen',
  'Kaartwijnen',
  'Likeuren',
  'Whiskey-Cognac',
  'Sapjes',
  'Borrelplanken',
];

export const CAT_COFFEE_WALRUS = ['Warme Dranken', 'IJskoffie', 'Gebak', 'Lekkers bij koffie'];

export const CAT_COFFEE_PROEFLOKAAL = [
  'Alle Warme Dranken',
  'Warme drankSpecials',
  'Ijskoffie',
  'Koffie Gebak',
];

export const CAT_COFFEE_GROOTE_WIELEN = ['Warme dranken', 'IJskoffie', 'Gebak'];

export const CATEGORY_TABLE = {
  [Establishment.WALRUS_LWD]: {
    CAT_LUNCH: CAT_LUNCH_WALRUS,
    CAT_DINNER: CAT_DINNER_WALRUS,
    CAT_DRINKS: CAT_DRINKS_WALRUS,
    CAT_COFFEE: CAT_COFFEE_WALRUS,
  },
  [Establishment.WALRUS_SN]: {
    CAT_LUNCH: CAT_LUNCH_WALRUS,
    CAT_DINNER: CAT_DINNER_WALRUS,
    CAT_DRINKS: CAT_DRINKS_WALRUS,
    CAT_COFFEE: CAT_COFFEE_WALRUS,
  },
  [Establishment.PROEFLOKAAL_SN]: {
    CAT_LUNCH: CAT_LUNCH_PROEFLOKAAL,
    CAT_DINNER: CAT_DINNER_PROEFLOKAAL,
    CAT_DRINKS: CAT_DRINKS_PROEFLOKAAL,
    CAT_COFFEE: CAT_COFFEE_PROEFLOKAAL,
  },
  [Establishment.GROOTE_WIELEN]: {
    CAT_LUNCH: CAT_LUNCH_GROOTE_WIELEN,
    CAT_DINNER: CAT_DINNER_GROOTE_WIELEN,
    CAT_DRINKS: CAT_DRINKS_GROOTE_WIELEN,
    CAT_COFFEE: CAT_COFFEE_GROOTE_WIELEN,
  },
};

export function getCatGroup(establishment: Establishment, cat: string): string {
  const categories = CATEGORY_TABLE[establishment];
  if (categories.CAT_LUNCH.includes(cat)) {
    return 'Lunch';
  }
  if (categories.CAT_DINNER.includes(cat)) {
    return 'Diner';
  }
  if (categories.CAT_DRINKS.includes(cat)) {
    return 'Borrelaars';
  }
  if (categories.CAT_COFFEE.includes(cat)) {
    return 'Koffiedrinkers';
  }
  return 'Onbekend';
}

export interface Period {
  type: PeriodType;
  year: number;
  month: number;
  week: number;
  date: string;
  customStart: string;
  customEnd: string;
}

export interface DateRange {
  d1: Date;
  d2: Date;
}

export interface Dictionary<T> {
  [key: string]: T;
}

export interface Employee {
  id: number;
  firstname: string;
  middlename: string;
  lastname: string;
  nickname: string;
}

export interface GeneralKPIData {
  guests: SalesMetaData[];
  revenue: SalesMetaData[];
}

export interface ProductCategoryKPIData {
  categories: string[];
  sales: Dictionary<SalesKpiData[]>;
}

export interface EmployeeProductCategoryKPIData {
  employeeId: number;
  categoryId: number;
  category: string;
  total: number;
  lunch: number;
  diner: number;
}

export interface EmployeeProductCategoryKPIDataDto {
  employees: Employee[];
  kpi: Dictionary<SalesKpiData2[]>;
}

export interface SalesData {
  guests: SalesMetaData[];
  revenue: SalesMetaData[];
  categories: string[];
  sales: Dictionary<SalesKpiData[]>;
}

export interface SalesMetaData {
  label: string;
  total: number;
  lunch: number;
  diner: number;
}

export interface SalesKpiData {
  id: number;
  label: string;
  name: string;
  total: number;
  lunch: number;
  diner: number;
}

export interface SalesKpiData2 {
  employeeId: number;
  categoryId: number;
  category: string;
  total: number;
  lunch: number;
  dinner: number;
}

export interface KPIPerPeriod {
  kpi: string;
  total: number;
  lunch: number;
  dinner: number;
}

export interface LocationKPIData {
  revenue: KPIPerPeriod[];
  guests: KPIPerPeriod[];
  spending: KPIPerPeriod[];
}

export interface MonetaryAmount {
  amount: number | null;
  currency: string;
  formatted: string;
}

export interface OrderedCategory {
  id: number;
  categoryId: number;
  categoryName: string;
  order: number;
}

export interface Product {
  id: number;
  name: string;
  price: number;
  tax: number;
}

export interface OrderedProduct {
  id: number;
  productId: number;
  productName: string;
  order: number;
}

export interface EmployeeSettings {
  id: number;
  establishment: Establishment;
  hidden: boolean;
}

export interface CreateEmployeeSettingsDto {
  id: number;
  hidden: boolean;
}

export interface CreateEmployeeSettingsBatchDto {
  data: CreateEmployeeSettingsDto[];
}

export interface DaypartKPI<T> {
  lunch: T;
  dinner: T;
  total: T;
}

export interface DaypartSalesCategoryKPI {
  id: number;
  name: string;
  sales: DaypartKPI<number>;
}

export interface GeneralKPI {
  revenue: DaypartKPI<MonetaryAmount>;
  guests: DaypartKPI<number>;
  spending: DaypartKPI<MonetaryAmount>;
  voids: MonetaryAmount;
  debit: MonetaryAmount;
}

export interface SalesCategoryKPI {
  categoryId: number;
  categoryName: string;
  lunch: number;
  dinner: number;
  total: number;
}

export interface ProductSalesKPI {
  productId: number;
  productName: string;
  group?: string;
  groupAggregate?: boolean;
  lunch: number;
  dinner: number;
  total: number;
}
