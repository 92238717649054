




















































import { Vue, Component, Watch } from 'vue-property-decorator';
import { DataModel, ProductList, ProductListDto } from '@/components/ProductList';
import Loading from '@/components/Loading.vue';
import ProductListForm from './ProductListForm.vue';

@Component({
  components: { DataModel, Loading, ProductListForm },
})
export default class UpdateProductList extends Vue {
  public id: number | null = null;

  public flashMessage = {
    text: '',
    variant: 'success',
  };

  @Watch('$route', { immediate: true, deep: true })
  public onRouteChange() {
    const id = Number.parseInt(this.$route.params.id, 10);
    if (!Number.isNaN(id)) {
      this.id = id;
    } else {
      this.id = null;
    }
  }

  public async updateProductList(
    model: ProductListDto,
    update: (model: ProductListDto) => Promise<void>,
  ) {
    this.flashMessage.text = '';
    await update(model);
  }

  public async removeProductList(productList: ProductList, remove: () => Promise<void>) {
    this.flashMessage.text = '';
    try {
      const confirmation = await this.$bvModal.msgBoxConfirm(
        `Weet je zeker dat je de productlijst '${productList.name}' wil verwijderen?`,
        {
          title: 'Productlijst verwijderen?',
          okVariant: 'danger',
          okTitle: 'Verwijderen',
          cancelTitle: 'Annuleren',
        },
      );
      if (confirmation) {
        remove();
      }
    } catch (e) {
      // ignore the error
    }
  }

  public onUpdated() {
    this.flashMessage.text = 'Productlijst is gewijzigd';
    this.flashMessage.variant = 'success';
  }

  public onDelete() {
    this.$router.push({ name: 'settingsView.productListOverview' });
  }
}
