import Vue from 'vue';
import { MutationTree } from 'vuex';
import { EmployeeState } from '.';
import { Employee } from '@/common/types';
import { SET_EMPLOYEES } from './mutation-types';

export default {
  [SET_EMPLOYEES](state, employees: Employee | Employee[]) {
    if (!Array.isArray(employees)) {
      employees = [employees];
    }

    employees.forEach(e => Vue.set(state.employees, e.id, e));
  },
} as MutationTree<EmployeeState>;
