import client from '@/api/alohaApiClient';
import { Establishment, MonetaryAmount } from '@/common';

export interface Void {
  employee: string;
  manager: number;
  item: string;
  price: MonetaryAmount;
  reason: string;
  table: string;
  check: string;
  time: string;
}

const resource = 'voids';

const api = {
  async list(establishment: Establishment, d1: string, d2: string) {
    const params = { d1, d2 };
    const response = await client.get<Void[]>(`${establishment}/${resource}`, { params });
    return response.data;
  },
};

export default api;
