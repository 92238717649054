
































import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Establishment } from '@/common';
import api, { Check, CheckItem } from '@/api/checkApi';

const appModule = namespace('app');

interface SortedData {
  itemId: number;
  item: string;
  count: number;
  price: number;
}

@Component
export default class CheckInfo extends Vue {
  @appModule.State('establishment')
  public establishment!: Establishment;

  @Prop({ required: true })
  public check!: number;

  @Prop({ required: true })
  public date!: string;

  public data: Check | null = null;
  public sortedData: SortedData[] = [];

  public loading = false;

  public tableFieldsFull = [
    {
      key: 'time',
      sortable: true,
      label: 'Tijd',
    },
    {
      key: 'item',
      sortable: true,
      label: 'Item',
    },
    {
      key: 'price.formatted',
      sortable: true,
      label: 'Prijs',
      tdClass: 'text-right',
      thClass: 'text-right',
    },
    {
      key: 'employee',
      sortable: true,
      label: 'Medewerker',
      tdClass: 'text-right',
      thClass: 'text-right',
    },
  ];

  public tableFieldsShort = [
    {
      key: 'item',
      sortable: true,
      label: 'Item',
    },
    {
      key: 'count',
      label: 'Aantal',
      tdClass: 'text-center',
      thClass: 'text-center',
    },
    {
      key: 'price',
      sortable: true,
      label: 'Prijs',
      tdClass: 'text-right',
      thClass: 'text-right',
    },
  ];

  public sortData(d: Check) {
    let currentItem = 0;
    d.lines.forEach((i: CheckItem) => {
      if (i.itemId !== currentItem) {
        currentItem = i.itemId;
        let priceAmount = 0;
        if (i.price.amount !== null) {
          priceAmount = i.price.amount;
        }
        const item = {
          itemId: i.itemId,
          item: i.item,
          count: 1,
          price: priceAmount,
        };
        this.sortedData.push(item);
      } else {
        this.sortedData.forEach((sortData: SortedData) => {
          if (sortData.itemId === i.itemId) {
            sortData.count = sortData.count + 1;
            if (i.price.amount != null) {
              sortData.price = sortData.price + i.price.amount;
            }
          }
        });
      }
    });
  }

  public async mounted() {
    await this.loadData();
  }

  public async loadData() {
    this.loading = true;
    try {
      this.data = await api.list(this.establishment, this.date, this.check);
    } finally {
      if (this.data != null) {
        this.sortData(this.data);
      }
      this.loading = false;
    }
  }

  public formatPrice(price: number) {
    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(price);
  }
}
