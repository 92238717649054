import Vue from 'vue';
import { nl as locale } from 'date-fns/locale';
import { format, formatDistanceToNow, parseISO } from 'date-fns';

Vue.filter('dateFormat', (d: Date | string, f: string) => {
  if (!(d instanceof Date)) {
    d = parseISO(d);
  }
  return format(d, f, { locale });
});

Vue.filter('formatDistanceToNow', (d: Date | string) => {
  if (!(d instanceof Date)) {
    d = parseISO(d);
  }
  return formatDistanceToNow(d, { addSuffix: true, locale });
});
