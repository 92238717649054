import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import api from '@/api/employeeApi';
import { Establishment } from '@/common';
import { EmployeeState } from '.';
import { SET_EMPLOYEES } from './mutation-types';

export default {
  async loadEmployee(
    { commit },
    { establishment, id }: { establishment: Establishment; id: number },
  ) {
    const employee = await api.getById(establishment, id);
    commit(SET_EMPLOYEES, employee);
  },

  async loadAllEmployees({ commit }, establishment: Establishment) {
    const employees = await api.findAll(establishment);
    commit(SET_EMPLOYEES, employees);
  },
} as ActionTree<EmployeeState, RootState>;
