




































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Api from '@/api/kpiApi';
import generalKPIApi from '@/api/generalKPIApi';
import salesCategoryKPIApi from '@/api/salesCategoryKPIApi';
import {
  Establishment,
  Period,
  GeneralKPI,
  SalesCategoryKPI,
  SalesData,
  EmployeeProductCategoryKPIDataDto,
} from '@/common';
import { parseISO, subWeeks, format, parse, addDays } from 'date-fns';
import Loading from '@/components/Loading.vue';
import DifferencePill from '@/components/DifferencePill.vue';
import Voids from './components/Voids.vue';
import Debtors from './components/Debtors.vue';

const appModule = namespace('app');

interface Data {
  name: string;
  current: number;
  previous: number;
}

interface PersonalData {
  name: string;
  worked: boolean;
  kpi: Kpi[];
}

interface Kpi {
  name: string;
  value: number;
}

@Component({
  components: { Loading, DifferencePill, Voids, Debtors },
})
export default class DayOverview extends Vue {
  @appModule.State('establishment')
  public establishment!: Establishment;

  @appModule.State('period')
  public period!: Period;

  public generalKPIDataset1: GeneralKPI | null = null;
  public generalKPIDataset2: GeneralKPI | null = null;

  public salesCategoryKPIDataset1: SalesCategoryKPI[] = [];
  public salesCategoryKPIDataset2: SalesCategoryKPI[] = [];

  public loading = false;

  public dataCurrent: SalesData | null = null;
  public dataLastYear: SalesData | null = null;
  public personalData: EmployeeProductCategoryKPIDataDto | null = null;
  public lunchData: Data[] = [];
  public dinerData: Data[] = [];
  public totalData: Data[] = [];
  public lunchEmployee: PersonalData[] = [];
  public dinerEmployee: PersonalData[] = [];
  public totalEmployee: PersonalData[] = [];

  public lunchView = true;
  public dinerView = true;
  public totalView = true;
  public lunchEmployeeView = false;
  public dinerEmployeeView = false;
  public totalEmployeeView = false;

  public async mounted() {
    await this.loadData();
  }

  get dinnerWorked() {
    return this.dinerEmployee.filter(e => e.worked);
  }

  get lunchWorked() {
    return this.lunchEmployee.filter(e => e.worked);
  }

  get dataset1Start() {
    return parseISO(this.period.date);
  }

  get dataset1End() {
    return addDays(this.dataset1Start, 1);
  }

  get dataset2Start() {
    return subWeeks(this.dataset1Start, 52);
  }

  get dataset2End() {
    return addDays(this.dataset2Start, 1);
  }

  get kpi() {
    if (!this.dataCurrent || !this.dataCurrent.sales) {
      return [];
    }
    return this.dataCurrent.sales[this.period.date];
  }

  get voidsTitle() {
    return 'Voids (' + this.generalKPIDataset1?.voids.formatted ?? 0 + ')';
  }

  get debitTitle() {
    return 'Debiteuren (' + this.generalKPIDataset1?.debit.formatted ?? 0 + ')';
  }

  @Watch('establishment')
  public onEstablishmentChange() {
    this.loadData();
  }

  @Watch('period', { deep: true })
  public onPeriodChange() {
    this.loadData();
  }

  public showAll() {
    this.lunchView = true;
    this.dinerView = true;
    this.totalView = true;
    this.lunchEmployeeView = false;
    this.dinerEmployeeView = false;
    this.totalEmployeeView = false;
  }

  public showEmployeeLunch() {
    this.lunchView = true;
    this.dinerView = false;
    this.totalView = false;
    this.lunchEmployeeView = true;
    this.dinerEmployeeView = false;
    this.totalEmployeeView = false;
  }

  public showEmployeeDiner() {
    this.lunchView = false;
    this.dinerView = true;
    this.totalView = false;
    this.lunchEmployeeView = false;
    this.dinerEmployeeView = true;
    this.totalEmployeeView = false;
  }

  public showEmployeeTotal() {
    this.lunchView = false;
    this.dinerView = false;
    this.totalView = true;
    this.lunchEmployeeView = false;
    this.dinerEmployeeView = false;
    this.totalEmployeeView = true;
  }

  public async loadData() {
    this.loading = true;

    try {
      await Promise.all([
        this.getData(),
        this.loadGeneralKPIData(),
        this.loadSalesCategoryKPIData(),
        this.getDataPersonal(),
      ]);
    } finally {
      this.loading = false;
    }
  }

  public async loadGeneralKPIData() {
    const ds1Start = format(this.dataset1Start, 'yyyy-MM-dd');
    const ds1End = format(this.dataset1End, 'yyyy-MM-dd');
    const ds2Start = format(this.dataset2Start, 'yyyy-MM-dd');
    const ds2End = format(this.dataset2End, 'yyyy-MM-dd');

    const ds1Response = await generalKPIApi.getPerDay(this.establishment, ds1Start, ds1End);
    const ds2Response = await generalKPIApi.getPerDay(this.establishment, ds2Start, ds2End);

    this.generalKPIDataset1 = ds1Response[ds1Start];
    this.generalKPIDataset2 = ds2Response[ds2Start];
  }

  public async loadSalesCategoryKPIData() {
    const ds1Start = format(this.dataset1Start, 'yyyy-MM-dd');
    const ds1End = format(this.dataset1End, 'yyyy-MM-dd');
    const ds2Start = format(this.dataset2Start, 'yyyy-MM-dd');
    const ds2End = format(this.dataset2End, 'yyyy-MM-dd');

    const ds1Response = await salesCategoryKPIApi.get(this.establishment, ds1Start, ds1End);
    const ds2Response = await salesCategoryKPIApi.get(this.establishment, ds2Start, ds2End);

    this.salesCategoryKPIDataset1 = ds1Response;
    this.salesCategoryKPIDataset2 = ds2Response;
  }

  public async getData() {
    this.lunchData = [];
    this.dinerData = [];
    this.totalData = [];
    this.lunchEmployee = [];
    this.dinerEmployee = [];
    this.totalEmployee = [];
    const d = parse(this.period.date, 'yyyy-MM-dd', new Date());
    const lyd = subWeeks(d, 52);
    const currentYearDate = format(d, 'yyyy-MM-dd');
    const currentYearDate2 = format(addDays(d, 1), 'yyyy-MM-dd');
    const lastYearDate = format(lyd, 'yyyy-MM-dd');
    const lastYearDate2 = format(addDays(lyd, 1), 'yyyy-MM-dd');

    this.dataCurrent = await Api.getSalesData(
      this.establishment,
      currentYearDate,
      currentYearDate2,
    );
    this.dataLastYear = await Api.getSalesData(this.establishment, lastYearDate, lastYearDate2);

    if (Object.keys(this.dataCurrent.sales).length > 0) {
      this.dataCurrent.sales[currentYearDate].forEach(current => {
        let previous = null;
        if (this.dataLastYear) {
          if (Object.keys(this.dataLastYear.sales).length > 0) {
            previous = this.dataLastYear.sales[lastYearDate].find(prev => prev.id === current.id);
          }
        }

        this.lunchData.push({
          name: current.name,
          current: current.lunch,
          previous: !previous ? 0 : previous.lunch,
        });
        this.dinerData.push({
          name: current.name,
          current: current.diner,
          previous: !previous ? 0 : previous.diner,
        });
        this.totalData.push({
          name: current.name,
          current: current.total,
          previous: !previous ? 0 : previous.total,
        });
      });
    }
  }

  public async getDataPersonal() {
    this.personalData = await Api.getEmployeeProductCategoryKPIData(
      this.establishment,
      this.period.date,
    );
    this.personalData.employees.forEach(e => {
      const lunchKpi: Kpi[] = [];
      const dinerKpi: Kpi[] = [];
      const totalKpi: Kpi[] = [];
      let lunch = false;
      let dinner = false;
      if (this.personalData) {
        this.personalData.kpi[e.id].forEach(kpi => {
          lunchKpi.push({
            name: kpi.category,
            value: kpi.lunch,
          });
          if (kpi.lunch > 0) {
            lunch = true;
          }
          dinerKpi.push({
            name: kpi.category,
            value: kpi.dinner,
          });
          if (kpi.dinner > 0) {
            dinner = true;
          }
          totalKpi.push({
            name: kpi.category,
            value: kpi.total,
          });
        });
      }
      this.lunchEmployee.push({
        name: e.nickname,
        worked: lunch,
        kpi: lunchKpi,
      });
      this.dinerEmployee.push({
        name: e.nickname,
        worked: dinner,
        kpi: dinerKpi,
      });
      this.totalEmployee.push({
        name: e.nickname,
        worked: true,
        kpi: totalKpi,
      });
    });
  }
}
