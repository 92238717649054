























import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { parseISO, format, setMonth } from 'date-fns';
import { nl as locale } from 'date-fns/locale';
import { Period, PeriodType } from '@/common';
import YearOverview from './YearOverview.vue';
import MonthOverview from './MonthOverview.vue';
import WeekOverview from './WeekOverview.vue';
import DayOverview from './DayOverview.vue';
import Sidebar from '@/components/Sidebar';
import EstablishmentSelect from '@/components/EstablishmentSelect.vue';
import PeriodPicker from '@/components/PeriodPicker.vue';

const appStore = namespace('app');

@Component({
  components: {
    YearOverview,
    MonthOverview,
    WeekOverview,
    DayOverview,
    Sidebar,
    EstablishmentSelect,
    PeriodPicker,
  },
})
export default class LocationView extends Vue {
  @appStore.State
  public period!: Period;

  get title() {
    switch (this.period.type) {
      case PeriodType.YEAR:
        return `Overzicht ${this.period.year}`;
      case PeriodType.MONTH:
        return `Overzicht ${format(setMonth(new Date(), this.period.month), 'MMMM', { locale })} ${
          this.period.year
        }`;
      case PeriodType.WEEK:
        return `Overzicht week ${this.period.week} - ${this.period.year}`;
      case PeriodType.DAY:
        return `Overzicht ${format(parseISO(this.period.date), 'dd-MM-yyyy')}`;
      default:
        return `Overzicht`;
    }
  }

  get overview() {
    return `${this.period.type.toLowerCase()}-overview`;
  }
}
