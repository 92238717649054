import client from './platformApiClient';
import { Establishment, ProductSalesKPI } from '@/common';

const resource = '/kpi/product-sales';

const productSalesKPIApi = {
  async get(establishment: Establishment, d1: string, d2: string, l: number) {
    const params = { d1, d2, l };
    const response = await client.get<ProductSalesKPI[]>(`${establishment}${resource}`, { params });
    return response.data;
  },
};

export default productSalesKPIApi;
