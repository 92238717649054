















































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class DifferencePill extends Vue {
  @Prop({ required: true, type: Number })
  public value1!: number;
  @Prop({ required: true, type: Number })
  public value2!: number;
  @Prop({ required: true })
  public tooltip!: string;
  @Prop({ type: Boolean })
  public badge = false;

  get tooltipStr() {
    return this.tooltip ? String(this.tooltip) : '';
  }

  get difference() {
    return this.value1 - this.value2;
    // if (this.value1 > this.value2) {
    //     return Math.round((this.value1 - this.value2) / this.value2 * 100);
    // } else if (this.value1 < this.value2) {
    //     return -Math.round((this.value2 - this.value1) / this.value1 * 100);
    // } else {
    //     return 0;
    // }
  }
}
