import client from '@/api/alohaApiClient';
import { Establishment, MonetaryAmount } from '@/common';

export interface Promotion {
  name: string;
  amount: number;
  total: MonetaryAmount;
}

const resource = 'promotion';

const api = {
  async list(establishment: Establishment, d1: string, d2: string) {
    const params = { d1, d2 };
    const response = await client.get<Promotion[]>(`${establishment}/${resource}`, { params });
    return response.data;
  },
};

export default api;
