import Vue from 'vue';
import Router from 'vue-router';
import LocationView from './views/location/LocationView.vue';
import EmployeeView from './views/employee/EmployeeView.vue';
import SettingsView from './views/settings/SettingsView.vue';
import EmployeeSettingsView from './views/settings/EmployeeSettingsView.vue';
import CategorySettingsView from './views/settings/CategorySettingsView.vue';
import ProductListView from './views/settings/productlist/ProductListView.vue';
import CreateProductListView from './views/settings/productlist/CreateProductListView.vue';
import UpdateProductListView from './views/settings/productlist/UpdateProductListView.vue';
import ProductView from './views/products/ProductView.vue';
import PromotionView from './views/promotions/PromotionView.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/vestiging',
      alias: '/',
      name: 'locationView',
      component: LocationView,
      meta: {
        sidebar: true,
      },
    },
    {
      path: '/medewerker',
      name: 'employeeView',
      component: EmployeeView,
      meta: {
        sidebar: true,
      },
    },
    {
      path: '/producten',
      name: 'productView',
      component: ProductView,
      meta: {
        sidebar: true,
      },
    },
    {
      path: '/promotion',
      name: 'promotionView',
      component: PromotionView,
      meta: {
        sidebar: true,
      },
    },
    {
      path: '/instellingen',
      redirect: '/instellingen/medewerkers',
      name: 'settingsView',
      component: SettingsView,
      meta: {
        sidebar: false,
      },
      children: [
        {
          path: 'medewerkers',
          name: 'settingsView.employee',
          component: EmployeeSettingsView,
          meta: {
            title: 'Medewerkers',
          },
        },
        {
          path: 'categorieen',
          name: 'settingsView.category',
          component: CategorySettingsView,
          meta: {
            title: 'Categorieën',
          },
        },
        {
          path: 'productlijsten',
          name: 'settingsView.productListOverview',
          component: ProductListView,
          meta: {
            title: 'Productlijsten overzicht',
          },
        },
        {
          path: 'productlijsten/nieuw',
          name: 'settingsView.createProductList',
          component: CreateProductListView,
          meta: {
            title: 'Nieuwe Productlijst',
          },
        },
        {
          path: 'productlijsten/:id',
          name: 'settingsView.updateProductList',
          component: UpdateProductListView,
          meta: {
            title: 'Productlijst Wijzigen',
          },
        },
      ],
    },
  ],
});
