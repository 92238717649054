


















































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as _ from 'lodash';
import {
  parseISO,
  format,
  getYear,
  getMonth,
  setMonth,
  addDays,
  subDays,
  getWeek,
  getISOWeeksInYear,
  startOfYear,
} from 'date-fns';
import { nl as locale } from 'date-fns/locale';
import { PeriodType, Period } from '@/common';

interface Option {
  value: number;
  text: string;
}

const appStore = namespace('app');

const MIN_YEAR = 2016;
const MAX_YEAR = getYear(new Date()) + 1;
const YEAR_OPTIONS = _.range(MIN_YEAR, MAX_YEAR + 1).map(y => {
  return { value: y, text: y.toString() };
});
const MONTH_OPTIONS = _.range(0, 12).map(m => {
  return { value: m, text: format(setMonth(new Date(), m), 'MMMM', { locale }) };
});

@Component
export default class PeriodPicker extends Vue {
  @appStore.State('period')
  public period!: Period;
  @appStore.Action('changePeriod')
  public changePeriod!: (period: Period) => Promise<void>;

  public yesterday = subDays(new Date(), 1);
  public PeriodType = PeriodType;

  public yearOptions: Option[] = YEAR_OPTIONS;
  public monthOptions: Option[] = MONTH_OPTIONS;

  public type = PeriodType.WEEK;
  public year = getYear(this.yesterday);
  public month = getMonth(this.yesterday);
  public week = getWeek(this.yesterday);
  public date = format(this.yesterday, 'yyyy-MM-dd');
  public customStart = format(this.yesterday, 'yyyy-MM-dd');
  public customEnd = format(new Date(), 'yyyy-MM-dd');

  get weekOptions() {
    const weeks = getISOWeeksInYear(startOfYear(this.year));
    return _.range(1, weeks + 1).map(w => {
      return { value: w, text: w.toString() };
    });
  }

  get customStartMax() {
    const d = parseISO(this.customEnd);
    return format(subDays(d, 1), 'yyyy-MM-dd');
  }

  get customEndMin() {
    const d = parseISO(this.customStart);
    return format(addDays(d, 1), 'yyyy-MM-dd');
  }

  public mounted() {
    this.load();
  }

  @Watch('period')
  public load() {
    this.type = this.period.type;
    this.year = this.period.year;
    this.month = this.period.month;
    this.week = this.period.week;
    this.date = this.period.date;
    this.customStart = this.period.customStart;
    this.customEnd = this.period.customEnd;
  }

  public isSelected(period: PeriodType) {
    return this.type === period ? 'secondary' : 'outline-secondary';
  }

  public select(period: PeriodType) {
    this.type = period;
  }

  public async submit() {
    this.changePeriod({
      type: this.type,
      year: this.year,
      month: this.month,
      week: this.week,
      date: this.date,
      customStart: this.customStart,
      customEnd: this.customEnd,
    });
  }
}
