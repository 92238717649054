var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-3"},[_c('b-button',{attrs:{"variant":"primary","to":{ name: 'settingsView.productListOverview' }}},[_vm._v(" Naar overzicht ")])],1),_c('b-card',{staticClass:"shadow mb-4",attrs:{"no-body":""}},[_c('b-card-body',[_c('p',[_vm._v(" Pas hieronder de naam en / of producten van de lijst aan en vergeet niet op 'opslaan' te klikken om de wijzigingen op te slaan. ")]),_c('b-alert',{attrs:{"variant":_vm.flashMessage.variant,"show":_vm.flashMessage.text !== '',"dismissible":""}},[_vm._v(" "+_vm._s(_vm.flashMessage.text)+" ")])],1),_c('b-card-body',{staticClass:"pt-0"},[_c('data-model',{attrs:{"id":_vm.id},on:{"updated":_vm.onUpdated,"deleted":_vm.onDelete},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var data = ref.data;
var update = ref.update;
var remove = ref.remove;
return [(loading)?_c('loading'):_c('product-list-form',{attrs:{"populate-with":data},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
return [_c('b-button',{attrs:{"variant":"primary","disabled":loading},on:{"click":function($event){return _vm.updateProductList(model, update)}}},[_vm._v(" Opslaan ")]),_c('b-button',{staticClass:"ml-3",attrs:{"variant":"danger","disabled":loading},on:{"click":function($event){return _vm.removeProductList(data, remove)}}},[_vm._v(" Verwijderen ")])]}}],null,true)})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }