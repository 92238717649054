













































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { parseISO, format, getISOWeek, addWeeks, subWeeks, getYear, addDays } from 'date-fns';
import { nl as locale } from 'date-fns/locale';
import api from '@/api/generalKPIApi';
import { Dictionary, Establishment, Period, PeriodType, GeneralKPI } from '@/common';
import Loading from '@/components/Loading.vue';
import GeneralKpiChart from './components/GeneralKPIChart.vue';
import GeneralKpiTable from './components/GeneralKPITable.vue';

const appModule = namespace('app');

interface Data {
  ds1Date: string;
  ds2Date: string;
  ds1Data: GeneralKPI;
  ds2Data: GeneralKPI;
}

@Component({
  components: { Loading, GeneralKpiChart, GeneralKpiTable },
})
export default class WeekOverview extends Vue {
  @appModule.State('establishment')
  public establishment!: Establishment;

  @appModule.State('period')
  public period!: Period;

  @appModule.Getter('selectedRange')
  public dateRange!: { d1: Date; d2: Date };

  @appModule.Action('changePeriod')
  public changePeriod!: (period: Period) => Promise<void>;

  public loading = false;
  public dataset1: Dictionary<GeneralKPI> | null = null;
  public dataset2: Dictionary<GeneralKPI> | null = null;

  public async mounted() {
    await this.loadData();
  }

  get nextWeek() {
    return addWeeks(this.dataset1Date, 1);
  }

  get prevWeek() {
    return subWeeks(this.dataset1Date, 1);
  }

  get dataset1Date() {
    return new Date(this.dateRange.d1);
  }

  get dataset2Date() {
    return subWeeks(this.dataset1Date, 52);
  }

  get dataset1Label() {
    return this.period.year;
  }

  get dataset2Label() {
    return this.period.year - 1;
  }

  get categories() {
    if (!this.dataset1) {
      return [];
    }

    return Object.keys(this.dataset1).map(x => ({
      label: format(parseISO(x), 'EEEE', { locale }),
    }));
  }

  get kpi() {
    if (!this.dataset1 || !this.dataset2) {
      return [];
    }

    const data: Data[] = [];
    const ds1Dates = Object.keys(this.dataset1);
    const ds2Dates = Object.keys(this.dataset2);

    for (let i = 0; i < ds1Dates.length; i++) {
      const ds1Date = ds1Dates[i];
      const ds1Data = this.dataset1[ds1Date];
      const ds2Date = ds2Dates[i];
      const ds2Data = this.dataset2[ds2Date];

      data.push({
        ds1Date,
        ds1Data,
        ds2Date,
        ds2Data,
      });
    }

    return data;
  }

  @Watch('establishment')
  public async onEstablishmentChange() {
    await this.loadData();
  }

  @Watch('period', { deep: true })
  public async onPeriodChange() {
    await this.loadData();
  }

  public async loadData() {
    this.loading = true;

    const ds1Start = format(this.dateRange.d1, 'yyyy-MM-dd');
    const ds1End = format(this.dateRange.d2, 'yyyy-MM-dd');
    const ds2Start = format(this.dataset2Date, 'yyyy-MM-dd');
    const ds2End = format(addDays(this.dataset2Date, 7), 'yyyy-MM-dd');

    try {
      this.dataset1 = await api.getPerDay(this.establishment, ds1Start, ds1End);
      this.dataset2 = await api.getPerDay(this.establishment, ds2Start, ds2End);
    } finally {
      this.loading = false;
    }
  }

  public gotoWeek(date: Date) {
    const week = getISOWeek(date);
    const year = getYear(date);

    this.changePeriod({
      type: PeriodType.WEEK,
      year,
      month: this.period.month,
      week,
      date: this.period.date,
      customStart: this.period.customStart,
      customEnd: this.period.customEnd,
    });
  }

  public gotoDate(date: string) {
    this.changePeriod({
      type: PeriodType.DAY,
      year: this.period.year,
      month: this.period.month,
      week: this.period.week,
      date,
      customStart: this.period.customStart,
      customEnd: this.period.customEnd,
    });
  }
}
