







import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Establishment } from '@/common/types';

const appModule = namespace('app');

@Component
export default class EstablishmentSelect extends Vue {
  @Prop({ type: Boolean, default: false, required: false })
  public disabled!: boolean;

  @appModule.State('establishment')
  public establishment!: Establishment;

  @appModule.Action
  public changeEstablishment!: (establishment: Establishment) => void;

  get selected() {
    return this.establishment;
  }

  set selected(establishment: Establishment) {
    this.changeEstablishment(establishment);
  }

  get options() {
    return [
      { value: 'WALRUS_SN', text: 'de Walrus - Sneek' },
      { value: 'WALRUS_LWD', text: 'de Walrus - Leeuwarden' },
      { value: 'PROEFLOKAAL_SN', text: 'Proeflokaal - Sneek' },
      { value: 'GROOTE_WIELEN', text: 'Groote Wielen' },
    ];
  }
}
