import { Module } from 'vuex';
import { RootState } from '@/store';
import { Dictionary, Employee } from '@/common/types';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export interface EmployeeState {
  employees: Dictionary<Employee>;
}

const state: EmployeeState = {
  employees: {},
};

const namespaced = true;

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
} as Module<EmployeeState, RootState>;
