import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import apiClient from '@/api/platformApiClient';
import { ProductList } from '@/components/ProductList/types';

const END_POINT = 'product-list';

@Component
export default class DataList extends Vue {
  @Prop({ required: true, type: String })
  public establishment!: string;

  public loading = false;
  public error: string | null = null;
  public data: ProductList[] = [];

  @Watch('data')
  public onDataChange() {
    if (this.data.length > 0) {
      this.$emit('peek', this.data[0]);
    }
  }

  @Watch('establishment', { immediate: true })
  public async loadData() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    try {
      const response = await apiClient.get<ProductList[]>(`${this.establishment}/${END_POINT}`);
      this.data = response.data;
      this.error = null;
    } catch (e) {
      this.error = e.response;
    } finally {
      this.loading = false;
    }
  }

  public render() {
    if (!this.$scopedSlots.default) {
      return;
    }

    return this.$scopedSlots.default({
      loading: this.loading,
      error: this.error,
      data: this.data,
    });
  }
}
