import client from './platformApiClient';
import { Dictionary, Establishment, SalesCategoryKPI } from '@/common';

const resource = 'kpi/sales-category';

const generalKPIApi = {
  async get(establishment: Establishment, d1: string, d2: string) {
    const params = { d1, d2 };
    const response = await client.get<SalesCategoryKPI[]>(`${establishment}/${resource}`, {
      params,
    });
    return response.data;
  },

  async getByEmployee(establishment: Establishment, id: number, d1: string, d2: string) {
    const params = { d1, d2 };
    const response = await client.get<SalesCategoryKPI[]>(
      `${establishment}/${resource}/employee/${id}`,
      { params },
    );
    return response.data;
  },

  async getPerDay(establishment: Establishment, d1: string, d2: string) {
    const params = { d1, d2 };
    const response = await client.get<Dictionary<SalesCategoryKPI[]>>(
      `${establishment}/${resource}/per-day`,
      { params },
    );
    return response.data;
  },
};

export default generalKPIApi;
