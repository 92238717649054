import { Module } from 'vuex';
import { RootState } from '@/store';
import { Establishment, Period, PeriodType, Employee } from '@/common';
import { getYear, getMonth, format, getISOWeek } from 'date-fns';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export interface AppState {
  establishment: Establishment;
  period: Period;
  employee: Employee;
}

const today = new Date();

const state: AppState = {
  establishment: Establishment.WALRUS_SN,
  period: {
    type: PeriodType.WEEK,
    year: getYear(today),
    month: getMonth(today),
    week: getISOWeek(today),
    date: format(today, 'yyyy-MM-dd'),
    customStart: format(today, 'yyyy-MM-dd'),
    customEnd: format(new Date(), 'yyyy-MM-dd'),
  },
  employee: {
    id: 0,
    firstname: '',
    middlename: '',
    lastname: '',
    nickname: '',
  },
};

const namespaced = true;

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
} as Module<AppState, RootState>;
