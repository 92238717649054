















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { format } from 'date-fns';
import { Establishment, DateRange } from '@/common';
import { Debtor } from '@/api/debtorApi';
import api from '@/api/debtorApi';
import CheckInfo from './CheckInfo.vue';

const appModule = namespace('app');

@Component({
  components: { CheckInfo },
})
export default class Debtors extends Vue {
  @appModule.State('establishment')
  public establishment!: Establishment;

  @appModule.Getter('selectedRange')
  public selectedRange!: DateRange | null;

  public loading = false;
  public debtor: Debtor[] = [];
  public debtorAccount: Debtor[] = [];
  public data: Debtor[] = [];

  public tableFields = [
    {
      key: 'time',
      sortable: true,
      label: 'Tijd',
    },
    {
      key: 'check',
      sortable: true,
      label: 'Bon nr.',
    },
    {
      key: 'identification',
      sortable: true,
      label: 'Debiteur',
    },
    {
      key: 'employee',
      sortable: true,
      label: 'Medewerker',
    },
    {
      key: 'manager',
      sortable: true,
      label: 'Manager',
    },
    {
      key: 'amount.formatted',
      label: 'Bedrag',
      tdClass: 'text-right',
      thClass: 'text-right',
    },
    {
      key: 'show_details',
      label: 'Details',
      tdClass: 'text-right',
      thClass: 'text-right',
    },
  ];

  public async mounted() {
    await this.loadData();
  }

  @Watch('selectedRange')
  public async onDateRangeChange() {
    this.loadData();
  }

  public async loadData() {
    if (!this.selectedRange) {
      return;
    }

    this.loading = true;

    const d1 = format(this.selectedRange.d1, 'yyyy-MM-dd');
    const d2 = format(this.selectedRange.d2, 'yyyy-MM-dd');
    try {
      this.debtor = await api.listDebtor(this.establishment, d1, d2);
      this.debtorAccount = await api.listDebtorAccount(this.establishment, d1, d2);
      this.data = this.debtor.concat(this.debtorAccount);
    } finally {
      this.loading = false;
    }
  }

  get totalAmount() {
    let amount = 0;
    this.data.forEach(e => {
      if (e.amount.amount) {
        amount = amount + e.amount.amount;
      }
    });
    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(amount);
  }
}
